import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import Footer from './footer';
import SkeletonLoader from './SkeletonLoader';
import './BinaryTree.css'; // Import the new CSS file for custom styles
import { Tooltip } from 'react-tooltip';
import Modal from 'react-modal';
import profile from './assets/profile.png'
import profile1 from './assets/profile2.png'
import New_Footer from './common/new_footer';
import Tree from './tree_node';

function Binary_Tree() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_id = searchParams.get("id");
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalData1, setModalData1] = useState({});
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Reset loading state when URL changes
      try {
        const response = await axios.get(`${apiBaseUrl}/userteamapi/${user_id}`);
        const formattedData = {
          id: '1',
          label: response.data.user.email, // Use the user's email as the top node label
          is_active: response.data.user.is_active, // Use the user's email as the top node label
          sponcer: response.data.user.sponcer_id,
          name: response.data.user.first_name,
          package: response.data.user.my_package,
          children: response.data.my_direct.map(user => ({
            id: user.email,
            user_id: user.id,
            sponcer: user.sponcer_id,
            name: user.first_name,
            package: user.my_package,
            label: user.email,
            is_active: user.is_active,
            children: [] // Add more logic if your data includes nested downline
          }))
        };

        setData(formattedData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user_id]); // Add user_id as a dependency to re-fetch when it changes

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleOpenModal = (data) => {
    setModalData(data);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);

  };

  const handleCloseModal1 = () => {
    setModalIsOpen1(false);

  };

 

  const handleOpenModal1 = (data, e) => {
    setModalData1(data);
    setModalIsOpen1(true);
    setModalPosition({ top: e.clientY + 10, left: e.clientX + 10 });
  };
  

  

  return (
    <div>
    <Sidebar />
    {data && <Tree data={[data]} />}
    <New_Footer />
  </div>
  );
}

export default Binary_Tree;


