import React from 'react';
import TreeNode from './TreeNode';
import './tree.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';


const Tree = ({ data }) => {
  const renderTree = (nodes) => {
    return (
      <div className="tree">
        <div className="tree-node-container">
          {nodes.map((node) => (
            <div key={node.id} className="tree-node-wrapper">
              <TreeNode node={node} />
              {node.children.length > 0 && (
                <Link to={`/binary_tree?id=${node.user_id || '#'}`}><div className="tree-children">
                    
                  {renderTree(node.children)}
                </div></Link>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return <div>{renderTree(data)}</div>;
};

export default Tree;
