import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';
import { BiClipboard } from 'react-icons/bi';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';
import frame from './assets/theme_asset/frame.jpg';


import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';
import './game.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';






function Deposite() {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiimageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  
  const [depositeCurrency , setdepositeCurrency] = useState('');
  const [recMetrics,setRecMetrics] = useState([0,0,0]);
  const [amount , setAmount] = useState('');
  const [usdt_amount , setUSDTAmount] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [modalMessage1, setModalMessage1] = useState({});
  const [fileValid, setFileValid] = useState(true);
  const [id, setId] = useState(''); // Define and initialize the id state
  const [receipt, setReceipt] = useState(''); // Define and initialize the receipt state
  const [tpin, setTpin] = useState('');
  const [bbnPrice, setBbnPrice] = useState(null);
  const [copied, setCopied] = useState(false);


  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      

      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  

 

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'receipt' && files.length > 0) {
      const selectedFile = files[0];
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  
      if (selectedFile && allowedTypes.includes(selectedFile.type)) {
        setFileValid(true);
        setReceipt(selectedFile);
      } else {
        setFileValid(false);
        e.target.value = null;
        return;
      }
    }
    // Update state based on input name
    if (name === 'amount') setAmount(value);
    else if (name === 'tpin') setTpin(value);
    else if (name === 'depositeCurrency') setdepositeCurrency(value);

  };
  
  const usdtamount = data && data.setting.usdt_amount;

      const usdt_to_pay = parseFloat((amount / usdtamount).toFixed(2));
    
 
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const usdtamount = data && data.setting.usdt_amount;

      const usdt_to_pay = parseFloat((amount / usdtamount).toFixed(2));
    
      // Create FormData object
      const formData = new FormData();
      // Append amount, user id, receipt file, and tpin to the FormData object
      formData.append('amount', amount);
      formData.append('id', data.user.id);
      formData.append('tpin', tpin);
      formData.append('depositeCurrency', depositeCurrency);
      formData.append('usdt_amount', usdt_to_pay);
      // Append receipt file to FormData
      if (receipt) {
        formData.append('receipt', receipt);
      }
  
      
      if (amount < 500) {
        // Show error message or handle invalid amount
        setModalMessage({
          title: 'Invaild Balance',
          content: 'Please Enter the amount greater than 500',
        });
        setShowModal(true);
        return;// Stop further execution
    }

      // Send POST request with FormData
      const response = await axios.post(`${apiBaseUrl}/make_deposite`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
        },
      });
      setModalMessage1({
        title: 'Deposte Request send Successfully',
        content: 'You will receive your deposite amount with in 1 hour successfully',
      });
      setShowModal1(true);
      navigate('/deposite');
      fetchData(); // Redirect the user to the homepage after successful registration
      // You can add any further actions here, like redirecting the user or showing a success message
    } catch (error) {
      setModalMessage({
        title: 'Error',
        content: 'Some thing went wrong. Please insert all details or try again later',
      });
      setShowModal(true);
      // Handle error, show error message, etc.
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  
  const closeModal1 = () => {
    setShowModal1(false);
   };

  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/deposite');
    }
    feather.replace();
  
    fetchData();
  }, []);
  
  const handleButtonClick = (value) => {
    setAmount(value);
  };
  
 
  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/deposite');
    }
    feather.replace();

    fetchData();
  }, []);

  const copyToClipboard = () => {
const textToCopy = data && data.qr_image[1] && data.qr_image[1].address;

  
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setCopied(true); // Set copied state to true
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        })
        .catch((error) => console.error('Could not copy text: ', error));
    } else {
      // Fallback for browsers that don't support clipboard API
      const textField = document.createElement('textarea');
      textField.innerText = textToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
  
      setCopied(true); // Set copied state to true
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    }
  };

  
  const copyToClipboard1 = () => {
    const textToCopy = data && data.qr_image[0] && data.qr_image[0].address;
    
      
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(textToCopy)
            .then(() => {
              setCopied(true); // Set copied state to true
              setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
            })
            .catch((error) => console.error('Could not copy text: ', error));
        } else {
          // Fallback for browsers that don't support clipboard API
          const textField = document.createElement('textarea');
          textField.innerText = textToCopy;
          document.body.appendChild(textField);
          textField.select();
          document.execCommand('copy');
          textField.remove();
      
          setCopied(true); // Set copied state to true
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        }
      };

      const copyToClipboard2 = () => {
        const textToCopy = data && data.qr_image[2] && data.qr_image[2].address;
        
          
            if (navigator.clipboard && navigator.clipboard.writeText) {
              navigator.clipboard.writeText(textToCopy)
                .then(() => {
                  setCopied(true); // Set copied state to true
                  setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
                })
                .catch((error) => console.error('Could not copy text: ', error));
            } else {
              // Fallback for browsers that don't support clipboard API
              const textField = document.createElement('textarea');
              textField.innerText = textToCopy;
              document.body.appendChild(textField);
              textField.select();
              document.execCommand('copy');
              textField.remove();
          
              setCopied(true); // Set copied state to true
              setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
            }
          };
  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page
    navigate('/login');
  };
  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }


  
  return (
  <body className=''>
    
    <div className=''>
     {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
     {showModal1 && <Modal message={modalMessage1} closeModal={closeModal1} />}
       
     <Sidebar />
  {/* header end */}
  {/* login section start */}
 
  {/* <div className="alert alert-info mt-5" style={{fontSize:'15px'}} role="alert">
  ⚠️ Only send DBTC Token (BEP20) assets to this address
  Other assets will be lost forever.
</div> */}


  <form className="auth-form-1" onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="custom-container-1">
        
          <div className="form-group">
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="amount"
                name="amount"
                placeholder="Enter Token Quantity/Amount"
                value={amount}
                onChange={handleChange}
                required
              />

                <input
                      type="hidden"
                      className="form-control"
                      id="id"
                      name="id"
                      placeholder="Enter your ID"
                      value={data.user.id}
                    />
              
            </div>
          </div>
         
          <div>
            <div className='row m-2'>
            <button type="button" style={{backgroundColor:'#8c45cd',color:'white'}} className='col btn  p-1 m-1' onClick={() => handleButtonClick('2500')}>2500</button>
            <button type="button" style={{backgroundColor:'#8c45cd',color:'white'}} className='col btn  p-1 m-1' onClick={() => handleButtonClick('25000')}>25000</button>
            <button type="button" style={{backgroundColor:'#8c45cd',color:'white'}} className='col btn  p-1 m-1' onClick={() => handleButtonClick('50000')}>50000</button>
            <button type="button" style={{backgroundColor:'#8c45cd',color:'white'}} className='col btn  p-1 m-1' onClick={() => handleButtonClick('100000')}>100000</button>
            </div>
            
        </div>
        <div className="form-group mb-2">
    <label htmlFor="depositeCurrency" className="form-label" style={{ color: 'black' }}>Select Deposite Currency:</label>
    <div className="form-input">
        <select
            className="form-select"
            id="depositeCurrency"
            name="depositeCurrency"
            onChange={handleChange}
            required
        >
            <option value="">Select Currency</option>
            <option value="usd">Deposite in USD</option>
            <option value="dgb">Deposite in DGB</option>
            <option value="inr">Deposite in INR</option> 
        </select>
    </div>
</div>
{depositeCurrency === 'usd' && (
   <div className="form-group">
        <label htmlFor="depositeCurrency" className="form-label" style={{ color: 'black' }}>This Amount Should Pay in USDT</label>

   <div className="form-input">
     <input
       type="text"
       className="form-control"
       id="usdt_amount"
       name="usdt_amount"
       placeholder="Enter Token Quantity/Amount"
       value={usdt_to_pay}
       onChange={handleChange}
       readOnly
     />

       
     
   </div>
 </div>
)} 

      <div className="form-group">
        <label htmlFor="name" className="form-label">
          Payment QR code 
        </label>
        
        {depositeCurrency === 'inr' && (
   <div className="form-input row">
   <center><img src={`${apiimageUrl}/qrcode/${data && data.qr_image[1] && data.qr_image[1].qr}`} alt="frame" style={{width:'180px'}}/></center>
   <div className="d-flex " style={{justifyContent:'space-between'}}>
      <div style={{ wordWrap: 'break-word', maxWidth: '75%', overflowWrap: 'break-word' }}>Address : {data && data.qr_image[1] && data.qr_image[1].address}</div>
      {!copied && (
<button className="btn d-flex btn-link " style={{textDecoration:'none', color:'black'}} onClick={copyToClipboard}>
Copy <BiClipboard style={{ marginLeft: '2px', color: 'black' }} />
</button>
)}
{copied && <span className="text-success">Copied!</span>}


          </div>
  </div>
)} 
{depositeCurrency === 'dgb' && (
   <div className="form-input row">
   <center><img src={`${apiimageUrl}/qrcode/${data && data.qr_image[0] && data.qr_image[0].qr}`} alt="frame" style={{width:'180px'}}/></center>
   <div className="d-flex " style={{justifyContent:'space-between'}}>
      <div style={{ wordWrap: 'break-word', maxWidth: '75%', overflowWrap: 'break-word' }}>Address : {data && data.qr_image[0] && data.qr_image[0].address}</div>
      {!copied && (
<button className="btn d-flex btn-link " style={{textDecoration:'none', color:'black'}} onClick={copyToClipboard1}>
Copy <BiClipboard style={{ marginLeft: '2px', color: 'black' }} />
</button>
)}
{copied && <span className="text-success">Copied!</span>}


          </div>
  </div>
)} 
{depositeCurrency === 'usd' && (
   <div className="form-input row">
   <center><img src={`${apiimageUrl}/qrcode/${data && data.qr_image[2] && data.qr_image[2].qr}`} alt="frame" style={{width:'180px'}}/></center>
   <div className="d-flex " style={{justifyContent:'space-between'}}>
   <div style={{ wordWrap: 'break-word', maxWidth: '75%', overflowWrap: 'break-word' }}>
    Address: {data && data.qr_image[2] && data.qr_image[2].address}
</div>

      {!copied && (
<button className="btn d-flex btn-link " style={{textDecoration:'none', color:'black'}} onClick={copyToClipboard2}>
Copy <BiClipboard style={{ marginLeft: '2px', color: 'black' }} />
</button>
)}
{copied && <span className="text-success">Copied!</span>}


          </div>
  </div>
)} 
 

        
      </div>
      <div className="form-group">
            <label htmlFor="receipt" className="form-label">Payment Receipt</label>
            <div className="form-input">
              <input
                type="file"
                className="form-control"
                id="receipt"
                name="receipt"
                accept=".png, .jpg, .jpeg"
                onChange={handleChange}
                required
              />
            </div>
            {!fileValid && <div className="text-danger">Please select a valid image file (JPEG, PNG, JPG)</div>}
          </div>
          <div className="form-group mb-2">
            <label htmlFor="tpin" className="form-label" style={{color:'black'}}></label>
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="tpin"
                name="tpin"
                placeholder="Enter your Transaction Hash"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <button type='submit' className="btn theme-btn w-100" style={{ color: 'white' }}>Deposit</button>
         
        </div>
        
      </form>
  
       <Footer />
        
      </div>
      </body>
  );
}

 


export default Deposite;
 