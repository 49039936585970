
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo1 from "../assets/theme_asset/logo/CAPITAL.png";

import { Link, useLocation  } from "react-router-dom";

import "../new_dash.css";

import { useEffect } from "react";

function New_Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  // Function to handle click on a list item

  return (
    <div style={{ overflow: "hidden" }}>
      
      <section className="footer-content">
        <div className="footer-body">
        <div className={`${location.pathname === '/' ? 'active-tab' : ''} align-content-center`}>
        <Link to={'/'}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M9 21H7a4 4 0 0 1-4-4v-6.292a4 4 0 0 1 1.927-3.421l5-3.03a4 4 0 0 1 4.146 0l5 3.03A4 4 0 0 1 21 10.707V17a4 4 0 0 1-4 4h-2m-6 0v-4a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v4m-6 0h6"
              />
            </svg>
            </Link>
          </div>

          <div className={`${location.pathname === '/New_deposite' ? 'active-tab' : ''} align-content-center`}>
            <Link to={'/new_deposite'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                <path fill="black" d="M15 3a1 1 0 0 1 1 1v2h4a1 1 0 0 1 1 1v12h2v2H1v-2h2V7a1 1 0 0 1 1-1h4V4a1 1 0 0 1 1-1zm-5 5H8v11h2zm6 0h-2v11h2zm-2-3h-4v1h4z" />
              </svg>
            </Link>
          </div> 
          
          <div className={`${location.pathname === '/activation' ? 'active-tab' : ''} align-content-center`}>
            <Link to={'/activation'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="#000" d="M5.616 20q-.691 0-1.153-.462T4 18.384V5.616q0-.691.463-1.153T5.616 4h12.769q.69 0 1.153.463T20 5.616v12.769q0 .69-.462 1.153T18.384 20zM7 16.5h5v-1H7zm2-4.538l3-1.5l3 1.5V5H9z"/></svg></Link>
          </div>
          <div className={`${location.pathname === '/profile' ? 'active-tab' : ''} align-content-center`}>
              <Link to={'/profile'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                  <path fill="black" fill-rule="evenodd" d="M8 7a4 4 0 1 1 8 0a4 4 0 0 1-8 0m0 6a5 5 0 0 0-5 5a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3a5 5 0 0 0-5-5z" clip-rule="evenodd" />
                </svg>
              </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default New_Footer;
