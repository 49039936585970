import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import feather from 'feather-icons';
import Modal from './modal';
import SkeletonLoader from './SkeletonLoader';
import auth6 from './assets/theme_asset/authentication/6.svg';
import './App.css';

function Account() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [recMetrics, setRecMetrics] = useState([0, 0, 0]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});

  const [passbookImage, setPassbookImage] = useState(null);
  const [passbookImageError, setPassbookImageError] = useState(null);

  const allowedExtensions = ['jpg', 'jpeg', 'png'];

  const handlePassbookImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        setPassbookImageError('Please select a valid image file (jpg, jpeg, png)');
      } else {
        setPassbookImage(file);
        setPassbookImageError(null);
      }
    }
  };

  const fetchData = async () => {
    try {
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!passbookImage) {
      setPassbookImageError('Please select a passbook image');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('name', e.target.name.value);
      formData.append('id', e.target.id.value);
      formData.append('mobile', e.target.mobile.value);
      formData.append('email', e.target.email.value);
      formData.append('upiid', e.target.upiid.value);
      formData.append('bank_name', e.target.bank_name.value);
      formData.append('acc_no', e.target.acc_no.value);
      formData.append('ifsc', e.target.ifsc.value);
      formData.append('pass_img', passbookImage);


      const response = await axios.post(`${apiBaseUrl}/update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setModalMessage({
        title: 'User updated Successfully',
        content: 'You have updated the information successfully',
      });
      setShowModal(true);
      navigate('/account');
    } catch (error) {
      console.error('Error updating user:', error);
      let errorMessage = 'An error occurred';
      if (error.response && error.response.data) {
        errorMessage = error.response.data.message; // This should give you ["Insufficient Balance"]
      }
      setModalMessage({
        title: 'Error',
        content: `${errorMessage}`,
      });
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token == null) {
      navigate('/login');
    } else {
      navigate('/account');
    }
    feather.replace();
    fetchData();
  }, []);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  if (data == null) {
    return <></>;
  }

  return (
    <div>
      {showModal && <Modal message={modalMessage} closeModal={closeModal} />}

      <div className="auth-header">
        <div>
          <Link to="/profile" style={{ textDecoration: 'none', marginTop: '20px', paddingLeft: '10px' }}>
            <button
              style={{
                padding: '8px 16px',
                backgroundColor: 'transparent',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              X
            </button>
          </Link>
        </div>
        <img className="img-fluid img" src={auth6} alt="v1" />
        <div className="auth-content">
          <div>
            <h2>Welcome</h2>
            <h4 className="p-0">Update the information</h4>
          </div>
        </div>
      </div>

      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="custom-container">
          <div className="form-group">
            <label htmlFor="name" className="form-label">
              Full name
            </label>
            <div className="form-input">
              <input
                type="text"
                className="form-control-new"
                id="name"
                name="name"
                defaultValue={data.user.first_name}
                placeholder="Enter your name"
              />
              <input
                type="hidden"
                className="form-control-new"
                id="id"
                name="id"
                defaultValue={data.user.id}
                placeholder="Enter your ID"
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="mobile" className="form-label">
              Mobile
            </label>
            <div className="form-input">
              <input
                type="number"
                className="form-control-new"
                id="mobile"
                name="mobile"
                placeholder="Enter your Mobile"
                defaultValue={data.user.mobile}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email ID
            </label>
            <div className="form-input">
              <input
                type="email"
                className="form-control-new"
                id="email"
                name="email"
                placeholder="Enter Your Email ID"
                defaultValue={data.user.email1}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="bank_name" className="form-label">
              Bank Name
            </label>
            <div className="form-input">
              <input
                type="text"
                className="form-control-new"
                id="bank_name"
                name="bank_name"
                placeholder="Enter Your Bank Name"
                defaultValue={data.user.bank_name}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="acc_no" className="form-label">
              Account Number
            </label>
            <div className="form-input">
              <input
                type="text"
                className="form-control-new"
                id="acc_no"
                name="acc_no"
                placeholder="Enter Your Account Number"
                defaultValue={data.user.bank_acc_no}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="ifsc" className="form-label">
              IFSC
            </label>
            <div className="form-input">
              <input
                type="text"
                className="form-control-new"
                id="ifsc"
                name="ifsc"
                placeholder="Enter Your IFSC Code"
                defaultValue={data.user.bank_ifsc}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="pass_img" className="form-label">
              Upload Passbook front page Image
            </label>
            <div className="form-input">
              <input
                type="file"
                className="form-control-new"
                id="pass_img"
                name="pass_img"
                onChange={handlePassbookImageChange}
              />
              {passbookImageError && <p className="text-danger">{passbookImageError}</p>}
            </div>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="upiid" className="form-label">
              USDT Address
            </label>
            <div className="form-input">
              <input
                type="text"
                className="form-control-new"
                id="upiid"
                name="upiid"
                placeholder="Enter Your USDT Address"
                defaultValue={data.user.upi_id}
              />
            </div>
          </div>

          <button type="submit" className="short-btn w-100" style={{ color: 'white' }}>
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default Account;
