import React, { useEffect, useState } from "react";
import feather from "feather-icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

import "./App.css";
import "./sidebar.css";

import logo1 from "./assets/theme_asset/logo/CAPITAL.png";
import auth_bg from "./assets/theme_asset/background/card4.jpg";

function Sidebar() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isTeamOpen, setIsTeamOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [data, setData] = useState("");
  const [remainingTime, setRemainingTime] = useState(getRemainingTime());

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    feather.replace();
    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const toggleTeam = () => {
    setIsTeamOpen(!isTeamOpen);
  };
  const toggleReport = () => {
    setIsReportOpen(!isTeamOpen);
  };
  useEffect(() => {
    const timerId = setInterval(() => {
      setRemainingTime(getRemainingTime());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  function getRemainingTime() {
    const now = new Date();
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    const difference = endOfDay - now;
    return difference >= 0 ? formatTime(difference) : "00:00:00";
  }

  function formatTime(milliseconds) {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (milliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  }

  return (
    <div className="hearde-change">
      <div
        className={`offcanvas sidebar-offcanvas offcanvas-start${
          isSidebarOpen ? " show" : ""
        }`}
        tabIndex={-1}
        id="offcanvasLeft"
      >
        <div className="offcanvas-header sidebar-header">
          <a onClick={closeSidebar}>
            <i className="categories-icon" data-feather="x" />
          </a>

          <div className="sidebar-logo" style={{ marginTop: "-30px" }}>
            <img className="img-fluid logo" src={logo1} alt="logo" />
          </div>

          <div className="balance">
            <img
              className="img-fluid balance-bg"
              src={auth_bg}
              alt="auth-bg"
            />
            <h2>{data && data.user && data.user.first_name}</h2>
            <h5>Welcome to Capital Estate</h5>
          </div>
        </div>
        <div className="offcanvas-body">
          <div className="sidebar-content">
            <ul className="link-section">
              <li>
                <Link to="/" className="pages">
                  <i
                    className="sidebar-icon"
                    data-feather="home"
                    style={{ color: "#09cafe" }}
                  />
                  <h3>Home</h3>
                </Link>
              </li>
              <hr />
              <li>
                <div className="team-section">
                  <button
                    onClick={toggleTeam}
                    className="pages team-toggle"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <i
                      className="sidebar-icon"
                      data-feather="users"
                      style={{ color: "#09cafe" }}
                    />
                    <h3 style={{ flex: 1, margin: 0 }}>Team</h3>
                    <i
                      className={`team-toggle-icon ${
                        isTeamOpen ? "open" : ""
                      }`}
                      data-feather={isTeamOpen ? "chevron-up" : "chevron-down"}
                      style={{ color: "#09cafe" }}
                    />
                  </button>
                  {isTeamOpen && (
                    <ul className="sub-menu">
                      <li>
                        <Link to="/team" className="pages">
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                            <path fill="#09cafe" d="M14.754 10c.966 0 1.75.784 1.75 1.75v4.749a4.501 4.501 0 0 1-9.002 0V11.75c0-.966.783-1.75 1.75-1.75zm0 1.5H9.252a.25.25 0 0 0-.25.25v4.749a3.001 3.001 0 0 0 6.002 0V11.75a.25.25 0 0 0-.25-.25M3.75 10h3.381a2.74 2.74 0 0 0-.618 1.5H3.75a.25.25 0 0 0-.25.25v3.249a2.5 2.5 0 0 0 3.082 2.433c.085.504.24.985.453 1.432Q6.539 18.999 6 19a4 4 0 0 1-4-4.001V11.75c0-.966.784-1.75 1.75-1.75m13.125 0h3.375c.966 0 1.75.784 1.75 1.75V15a4 4 0 0 1-5.03 3.866c.214-.448.369-.929.455-1.433q.277.066.575.067a2.5 2.5 0 0 0 2.5-2.5v-3.25a.25.25 0 0 0-.25-.25h-2.757a2.74 2.74 0 0 0-.618-1.5M12 3a3 3 0 1 1 0 6a3 3 0 0 1 0-6m6.5 1a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5m-13 0a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5m6.5.5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m6.5 1a1 1 0 1 0 0 2a1 1 0 0 0 0-2m-13 0a1 1 0 1 0 0 2a1 1 0 0 0 0-2" />
                          </svg> <h3>Total Team</h3>
                        </Link>
                      </li>
                      <hr/>
                      <li>
                        <Link to="/direct" className="pages">
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 2048 2048">
                            <path fill="#09cafe" d="M1664 1088q66 0 124 25t102 68t69 102t25 125q0 52-16 101t-48 91v424l-256-128l-256 128v-424q-31-42-47-91t-17-101q0-66 25-124t68-102t102-69t125-25m0 128q-40 0-75 15t-61 41t-41 61t-15 75t15 75t41 61t61 41t75 15t75-15t61-41t41-61t15-75t-15-75t-41-61t-61-41t-75-15m128 600v-115q-60 27-128 27t-128-27v115l128-64zM1664 512q-53 0-99 20t-82 55t-55 81t-20 100q0 92-41 173t-116 137q19 9 36 20t35 23l-75 104q-49-35-106-54t-117-19q-80 0-149 30t-122 82t-83 123t-30 149H512q0-73 20-141t57-128t89-108t118-82q-74-55-115-136t-41-173q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100H0q0-52 14-101t39-93t62-80t83-62q-33-35-51-81t-19-95q0-53 20-99t55-82t81-55T384 0q53 0 99 20t82 55t55 81t20 100q0 49-18 95t-52 81q82 45 134 124q27-40 62-72t76-54t87-34t95-12q48 0 94 12t87 34t77 54t62 72q52-79 134-124q-33-35-51-81t-19-95q0-53 20-99t55-82t81-55t100-20q53 0 99 20t82 55t55 81t20 100q0 49-18 95t-52 81q46 26 82 62t62 79t40 93t14 102h-128q0-53-20-99t-55-82t-81-55t-100-20m-128-256q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50q0-27-10-50t-27-40t-41-28t-50-10q-27 0-50 10t-40 27t-28 41t-10 50m-1280 0q0 27 10 50t27 40t41 28t50 10q27 0 50-10t40-27t28-41t10-50q0-27-10-50t-27-40t-41-28t-50-10q-27 0-50 10t-40 27t-28 41t-10 50m512 512q0 53 20 99t55 82t81 55t100 20q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100" />
                          </svg> <h3>My Direct Team</h3>
                        </Link>
                      </li>
                      <hr/>
                      <li>
                        <Link to={`/binary_tree?id=${data.user.id || '#'}`} className="pages">
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256">
                            <path fill="#09cafe" d="M176 150h32a14 14 0 0 0 14-14v-32a14 14 0 0 0-14-14h-32a14 14 0 0 0-14 14v10H86V78h10a14 14 0 0 0 14-14V32a14 14 0 0 0-14-14H64a14 14 0 0 0-14 14v32a14 14 0 0 0 14 14h10v114a22 22 0 0 0 22 22h66v10a14 14 0 0 0 14 14h32a14 14 0 0 0 14-14v-32a14 14 0 0 0-14-14h-32a14 14 0 0 0-14 14v10H96a10 10 0 0 1-10-10v-66h76v10a14 14 0 0 0 14 14M62 64V32a2 2 0 0 1 2-2h32a2 2 0 0 1 2 2v32a2 2 0 0 1-2 2H64a2 2 0 0 1-2-2m112 128a2 2 0 0 1 2-2h32a2 2 0 0 1 2 2v32a2 2 0 0 1-2 2h-32a2 2 0 0 1-2-2Zm0-88a2 2 0 0 1 2-2h32a2 2 0 0 1 2 2v32a2 2 0 0 1-2 2h-32a2 2 0 0 1-2-2Z" />
                          </svg> <h3>Tree</h3>
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
              <hr />
              <li>
                <Link to="/profile" className="pages">
                  <i
                    className="sidebar-icon"
                    data-feather="user"
                    style={{ color: "#09cafe" }}
                  />
                  <h3>Profile</h3>
                </Link>
              </li>
              <hr />
              <li>
                <Link to="/activation" className="pages">
                  <i
                    className="sidebar-icon"
                    data-feather="package"
                    style={{ color: "#09cafe" }}
                  />
                  <h3>Activation</h3>
                </Link>
              </li>
              <hr />
              <li>
                <Link to="/New_deposite" className="pages">
                  <i
                    className="sidebar-icon"
                    data-feather="briefcase"
                    style={{ color: "#09cafe" }}
                  />
                  <h3>Deposite</h3>
                </Link>
              </li>
              <hr />

              <li>
                
                <Link to="/New_report" className="pages">
                  <i
                    className="sidebar-icon"
                    data-feather="command"
                    style={{ color: "#09cafe" }}
                  />
                  <h3>All Incomes</h3>
                </Link>
              </li>
              <hr />

              {/* <li>
                <Link to="/withdraw" className="pages">
                  <i
                    className="sidebar-icon"
                    data-feather="dollar-sign"
                    style={{ color: "#09cafe" }}
                  />
                  <h3>Withdraw</h3>
                </Link>
              </li> */}
              {/* <hr /> */}

              <li>
                <Link to="/about" className="pages">
                  <i
                    className="sidebar-icon"
                    data-feather="info"
                    style={{ color: "#09cafe" }}
                  />
                  <h3>About us</h3>
                </Link>
              </li>
              <hr />

              <li>
                <Link to="/terms" className="pages">
                  <i
                    className="sidebar-icon"
                    data-feather="file-text"
                    style={{ color: "#09cafe" }}
                  />
                  <h3>Terms and Condition</h3>
                </Link>
              </li>
              <hr />

              <li>
                <Link to="#" onClick={handleLogout} className="pages">
                  <i
                    className="sidebar-icon"
                    data-feather="log-out"
                    style={{ color: "#09cafe" }}
                  />
                  <h3>Log out</h3>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="section-t-space" style={{ paddingTop: "0px" }}>
        <div className="custom-container">
          <div className="header-panel" style={{ height: "100px" }}>
            <button className="sidebar-btn" onClick={toggleSidebar}>
              <i className="menu-icon" data-feather="menu" />
            </button>
            <img
              className="img-fluid logo"
              src={logo1}
              alt="logo"
              style={{ width: "160px" }}
            />
          </div>
        </div>
      </header>
    </div>
  );
}

export default Sidebar;
