import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';
import { BiClipboard } from 'react-icons/bi';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';
import frame from './assets/theme_asset/frame.jpg';


import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';
import './game.css';
import './new_dash.css';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';
import New_Footer from './common/new_footer';



const MySwal = withReactContent(Swal);



function Activation() {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0]);
  const [amount , setAmount] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [modalMessage1, setModalMessage1] = useState({});
  const [fileValid, setFileValid] = useState(true);
  const [id, setId] = useState(''); // Define and initialize the id state
  const [receipt, setReceipt] = useState(''); // Define and initialize the receipt state
  const [tpin, setTpin] = useState('');
  const [bbnPrice, setBbnPrice] = useState(null);
  const [copied, setCopied] = useState(false);
  const [activateid, setActivateid] = useState(null);
  const [input_amount, setInput_Amount] = useState(5000);

  const handleAmountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    
      setInput_Amount(value);
    
  };

  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);

      setData(response.data);


    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData1 = async (refferalid) => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      const userData = response.data.getalluser;

      
     

      let found = false;
      let user_name = null;

      userData.forEach(user => {
        // Check if the user's email matches the referral id
        if (user.email === refferalid) {
          found = true;
          user_name = user.first_name;
        }
      });
  
      if (found) {
        MySwal.fire({
          icon: 'success',
          title: 'ID Found',
          text: `This ID is of ${user_name}` ,
        });
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'ID Not Found',
          text: 'This ID does not exist in the database!',
        });
      }

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
   
    // Update state based on input name
    if (name === 'amount') setAmount(value);
    else if (name === 'tpin') setTpin(value);
    else if (name === 'refferalid') setActivateid(value);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const amount = parseInt(input_amount, 10);
    if (amount >= 5000 && amount % 5000 === 0) {
      // Proceed with form submission logic
      console.log('Form submitted with amount:', amount);
    
      // Create FormData object
      const formData = new FormData();
      // Append amount, user id, receipt file, and tpin to the FormData object
      formData.append('amount', input_amount);
      formData.append('id', data.user.email);
      formData.append('tpin', tpin);
      formData.append('acitvateid', activateid);

      if (receipt) {
        formData.append('receipt', receipt);
      }
  
      
      if (amount < 1000) {
        // Show error message or handle invalid amount
        setModalMessage({
          title: 'Invaild Balance',
          content: 'Please Enter the amount greater than 1000',
        });
        setShowModal(true);
        return;// Stop further execution
    }

    
      // Send POST request with FormData
      const response = await axios.post(`${apiBaseUrl}/activate_package`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
        },
      });
      setModalMessage1({
        title: 'Package Acitvated Successfully',
        content: 'You Acitvated your Package successfully',
      });
      setShowModal1(true);
      fetchData();
      navigate('/activation');
    }else{
      setModalMessage({
        title: 'Invalid Input',
        content: 'Amount Should be greater than 5000 and multiple of 5000',
      });
      setShowModal(true);
    }

    } catch (error) {
      setModalMessage({
        title: 'Error',
        content: 'Some thing went wrong. Package not Activated',
      });
      setShowModal(true);
      console.log(error);
      // Handle error, show error message, etc.
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  
  const closeModal1 = () => {
    setShowModal1(false);
    
   };

  useEffect(() => {
    // Replace icons after component is mounted
   
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/activation');
    }
    feather.replace();
  
    fetchData();
  }, []);
  
  
  useEffect(() => {
    if (data && data.user && data.user.email) {
        setActivateid(data.user.email);
        fetchData1(data.user.email); // Optionally, fetch data when activating ID
    }
}, [data]);
  

  
  

  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

 
  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Handle change for dropdown selection
  const handleDropdownChange = (event) => {
    const selectedPackage = event.target.value;
    setAmount(selectedPackage);
  };

  const handleReferral = (e) => {
    const { name, value } = e.target;

    if (name === 'refferalid') {
      fetchData1(value);
      setActivateid(value); // Call fetchData only when referral ID changes
    }

    setActivateid(value);
  };
  
  return (
  <body className=''>
    
    <Sidebar />
     
      <div className='body-content'>
     {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
     {showModal1 && <Modal message={modalMessage1} closeModal={closeModal1} />}
     
  {/* header end */}
  {/* login section start */}
 
  

  <form className="auth-form-1" onSubmit={handleSubmit} encType="multipart/form-data">

        <div className="custom-container-1">
          
        <div className="form-group">
        <label htmlFor="Refferal ID" className="form-label">
          Acivate To
        </label>
        <div className="form-input mb-3">
          <input
            type="text"
            className="form-control-new"
            id="refferalid"
            name = "refferalid"
            defaultValue={data.user.email} 
            placeholder="Enter Your Refferal Id"
            onChange={handleChange}
            onBlur={handleReferral}
          />
        </div>
      </div>
      <div className="form-group mb-3">
        <label htmlFor="Refferal ID" className="form-label">
          Wallet Balance
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control-new"
            id="totalbalance"
            name = "totalbalance"
            defaultValue={data.wallet.activation_wallet} 
            readOnly
          />
        </div>
      </div>
        <label htmlFor="tpin" className="form-label" style={{color:'black'}}>Enter Package Amount</label>
          <div className="form-group">
            <div className="form-input mb-3">
            <input
              type="number"
              className="form-control-new"
              id="activate_package"
              name="activate_package"
              placeholder="Enter Amount"
             
              value={input_amount}
              onChange={handleAmountChange}
            />
            
                <input
                      type="hidden"
                      className="form-control-new"
                      id="id"
                      name="id"
                      placeholder="Enter your ID"
                      value={data.user.email}
                    />
              
            </div>
          </div>
         
         
        
     
    
          <div className="form-group mb-3">
            <label htmlFor="tpin" className="form-label" style={{color:'black'}}>Enter Password (For verification)</label>
            <div className="form-input">
              <input
                type="text"
                className="form-control-new"
                id="tpin"
                name="tpin"
                placeholder="Enter your Password"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          
          <button type='submit' className="btn theme-btn w-100" style={{ color: 'white' }}>Activate</button>
         
        </div>
        
      </form>
  
      
        
      </div>
      <New_Footer />
      </body>
  );
}

 


export default Activation;
 