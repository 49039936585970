import logo from "../logo.svg";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo1 from "../assets/theme_asset/logo/CAPITAL.png";

import { Link, useLocation  } from "react-router-dom";

import "../new_dash.css";

import { useEffect } from "react";
import New_Footer from "../common/new_footer";
import New_Header from "../common/new_header";
import Sidebar from "../Sidebar"
import Modal from "../modal";

function New_deposite() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiimageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  
  const [depositeCurrency , setdepositeCurrency] = useState('');
  const [recMetrics,setRecMetrics] = useState([0,0,0]);
  const [amount , setAmount] = useState('');
  const [usdt_amount , setUSDTAmount] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [modalMessage1, setModalMessage1] = useState({});
  const [fileValid, setFileValid] = useState(true);
  const [id, setId] = useState(''); // Define and initialize the id state
  const [receipt, setReceipt] = useState(''); // Define and initialize the receipt state
  const [tpin, setTpin] = useState('');
  const [bbnPrice, setBbnPrice] = useState(null);
  const [copied, setCopied] = useState(false);


  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      

      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  

 

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'receipt' && files.length > 0) {
      const selectedFile = files[0];
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  
      if (selectedFile && allowedTypes.includes(selectedFile.type)) {
        setFileValid(true);
        setReceipt(selectedFile);
      } else {
        setFileValid(false);
        e.target.value = null;
        return;
      }
    }
    // Update state based on input name
    if (name === 'amount') setAmount(value);
    else if (name === 'tpin') setTpin(value);
    else if (name === 'depositeCurrency') setdepositeCurrency(value);

  };
  
  const usdtamount = data && data.setting.usdt_amount;

      const usdt_to_pay = parseFloat((amount / usdtamount).toFixed(2));
    
 
 
  const handleSubmit = async (e) => {
    console.log('in the function')
    e.preventDefault();
    try {

      const usdtamount = data && data.setting.usdt_amount;

      const usdt_to_pay = parseFloat((amount / usdtamount).toFixed(2));
    
      // Create FormData object
      const formData = new FormData();
      // Append amount, user id, receipt file, and tpin to the FormData object
      formData.append('amount', amount);
      formData.append('id', data.user.id);
      formData.append('tpin', tpin);
      formData.append('depositeCurrency', depositeCurrency);
      formData.append('usdt_amount', usdt_to_pay);
      // Append receipt file to FormData
      if (receipt) {
        formData.append('receipt', receipt);
      }
  
      
      if (amount < 500) {
        // Show error message or handle invalid amount
        setModalMessage({
          title: 'Invaild Balance',
          content: 'Please Enter the amount greater than 500',
        });
        setShowModal(true);
        return;// Stop further execution
    }

      // Send POST request with FormData
      const response = await axios.post(`${apiBaseUrl}/make_deposite`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
        },
      });
      setModalMessage1({
        title: 'Deposte Request send Successfully',
        content: 'You will receive your deposite amount with in 1 hour successfully',
      });
      setShowModal1(true);
      navigate('/New_deposite');
      fetchData(); // Redirect the user to the homepage after successful registration
      // You can add any further actions here, like redirecting the user or showing a success message
    } catch (error) {
      setModalMessage({
        title: 'Error',
        content: 'Some thing went wrong. Please insert all details or try again later',
      });
      setShowModal(true);
      // Handle error, show error message, etc.
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  
  const closeModal1 = () => {
    setShowModal1(false);
   };

  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/New_deposite');
    }
  
    fetchData();
  }, []);
  
  const handleButtonClick = (value) => {
    setAmount(value);
  };
  
 
  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/New_deposite');
    }

    fetchData();
  }, []);

  const copyToClipboard = () => {
const textToCopy = data && data.qr_image[1] && data.qr_image[1].address;

  
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setCopied(true); // Set copied state to true
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        })
        .catch((error) => console.error('Could not copy text: ', error));
    } else {
      // Fallback for browsers that don't support clipboard API
      const textField = document.createElement('textarea');
      textField.innerText = textToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
  
      setCopied(true); // Set copied state to true
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    }
  };

  
  const copyToClipboard1 = () => {
    const textToCopy = data && data.qr_image[0] && data.qr_image[0].address;
    
      
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(textToCopy)
            .then(() => {
              setCopied(true); // Set copied state to true
              setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
            })
            .catch((error) => console.error('Could not copy text: ', error));
        } else {
          // Fallback for browsers that don't support clipboard API
          const textField = document.createElement('textarea');
          textField.innerText = textToCopy;
          document.body.appendChild(textField);
          textField.select();
          document.execCommand('copy');
          textField.remove();
      
          setCopied(true); // Set copied state to true
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        }
      };

      const copyToClipboard2 = () => {
        const textToCopy = data && data.qr_image[2] && data.qr_image[2].address;
        
          
            if (navigator.clipboard && navigator.clipboard.writeText) {
              navigator.clipboard.writeText(textToCopy)
                .then(() => {
                  setCopied(true); // Set copied state to true
                  setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
                })
                .catch((error) => console.error('Could not copy text: ', error));
            } else {
              // Fallback for browsers that don't support clipboard API
              const textField = document.createElement('textarea');
              textField.innerText = textToCopy;
              document.body.appendChild(textField);
              textField.select();
              document.execCommand('copy');
              textField.remove();
          
              setCopied(true); // Set copied state to true
              setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
            }
          };
  
  if (loading) {
    return <p>LOADING.....</p> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page
    navigate('/login');
  };
  
  if (loading) {
    return <p>LOADING.....</p> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Function to handle click on a list item

  return (
    <div style={{ overflow: "hidden" }}>
       {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
     {showModal1 && <Modal message={modalMessage1} closeModal={closeModal1} />}
     
     <Sidebar />
      <section className="body-content">
      <form className="auth-form-1" onSubmit={handleSubmit} encType="multipart/form-data">
        <div>
        
          <div className="form-group">
            <div className="form-input">
              <input
                type="text"
                className="form-control-new"
                id="amount"
                name="amount"
                placeholder="Enter Amount"
                value={amount}
                onChange={handleChange}
                required
              />

                <input
                      type="hidden"
                      className="form-control"
                      id="id"
                      name="id"
                      placeholder="Enter your ID"
                      value={data.user.id}
                    />
              
            </div>
          </div>
         
          <div>
            <div className='row m-3'>
            <button type="button"  className='col amount-short-btn  p-1 m-1' onClick={() => handleButtonClick('5000')}>5000</button>
            <button type="button"  className='col amount-short-btn  p-1 m-1' onClick={() => handleButtonClick('10000')}>10000</button>
            <button type="button"  className='col amount-short-btn  p-1 m-1' onClick={() => handleButtonClick('25000')}>25000</button>
            <button type="button"  className='col amount-short-btn  p-1 m-1' onClick={() => handleButtonClick('50000')}>50000</button>
            </div>
            
        </div>
        <div className="form-group mb-3">
    <label htmlFor="depositeCurrency" className="form-label" style={{ color: 'black' }}>Select Deposite Currency:</label>
    <div className="form-input">
        <select
            className="form-select"
            id="depositeCurrency"
            name="depositeCurrency"
            onChange={handleChange}
            required
        >
            <option value="">Select Currency</option>
            <option value="usd">Deposite in USD</option>
            <option value="inr">Deposite in INR</option> 
        </select>
    </div>
</div>
{depositeCurrency === 'usd' && (
   <div className="form-group">
        <label htmlFor="depositeCurrency" className="form-label" style={{ color: 'black' }}>This Amount Should Pay in USDT</label>

   <div className="form-input">
     <input
       type="text"
       className="form-control-new"
       id="usdt_amount"
       name="usdt_amount"
       placeholder="Enter Token Quantity/Amount"
       value={usdt_to_pay}
       onChange={handleChange}
       readOnly
     />

       
     
   </div>
 </div>
)} 

      <div className="form-group">
        <label htmlFor="name" className="form-label">
          Payment QR code 
        </label>
        
        {depositeCurrency === 'inr' && (
   <div className="form-input row">
   <center><img src={`${apiimageUrl}/qrcode/${data && data.qr_image[1] && data.qr_image[1].qr}`} alt="frame" style={{width:'180px'}}/></center>
   <div className="d-flex " style={{justifyContent:'space-between'}}>
      <div style={{ wordWrap: 'break-word', maxWidth: '75%', overflowWrap: 'break-word' }}>Address : {data && data.qr_image[1] && data.qr_image[1].address}</div>
      {!copied && (
<button className="btn d-flex btn-link " style={{textDecoration:'none', color:'black'}} onClick={copyToClipboard}>
Copy 
</button>
)}
{copied && <span className="text-success">Copied!</span>}


          </div>
  </div>
)} 
 
{depositeCurrency === 'usd' && (
   <div className="form-input row">
   <center><img src={`${apiimageUrl}/qrcode/${data && data.qr_image[2] && data.qr_image[2].qr}`} alt="frame" style={{width:'180px'}}/></center>
   <div className="d-flex " style={{justifyContent:'space-between'}}>
   <div style={{ wordWrap: 'break-word', maxWidth: '75%', overflowWrap: 'break-word' }}>
    Address: {data && data.qr_image[2] && data.qr_image[2].address}
</div>

      {!copied && (
<button className="btn d-flex btn-link " style={{textDecoration:'none', color:'black'}} onClick={copyToClipboard2}>
Copy 
</button>
)}
{copied && <span className="text-success">Copied!</span>}


          </div>
  </div>
)} 
 

        
      </div>
      <div className="form-group">
            <label htmlFor="receipt" className="form-label">Payment Receipt</label>
            <div className="form-input">
              <input
                type="file"
                className="form-control-new"
                id="receipt"
                name="receipt"
                accept=".png, .jpg, .jpeg"
                onChange={handleChange}
                required
              />
            </div>
            {!fileValid && <div className="text-danger">Please select a valid image file (JPEG, PNG, JPG)</div>}
          </div>
          <div className="form-group mb-3">
            <label htmlFor="tpin" className="form-label" style={{color:'black'}}></label>
            <div className="form-input">
              <input
                type="text"
                className="form-control-new"
                id="tpin"
                name="tpin"
                placeholder="Enter your Transaction Hash"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <button type='submit' className="short-btn w-100 br-50 " onClick={handleSubmit} style={{ color: 'white' }}>Deposit</button>
         
        </div>
        
      </form>
  
      </section>
     <New_Footer />
    </div>
  );
}

export default New_deposite;
