import React, { useState, useEffect } from 'react';
import  { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import Footer from './footer';
import search from './assets/search (1).png';
import auth6 from './assets/theme_asset/authentication/6.svg';
import './new_dash.css';
import SkeletonLoader from './SkeletonLoader';

function About() {
    const [setting, setSetting] = useState(null); // Declare setting variable here
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchData = async () => {
        try {
            const id = localStorage.getItem('id');
        const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
            setSetting(response.data.setting);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return  <SkeletonLoader showHeader={false} showFooter={false} />  ;
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    

    return (

        
        <div>

<div className="auth-header">
    <img className="Himg-fluid img" src={auth6} alt="v1" />
    <form className="theme-form p-2" target="_blank">
            <div className="form-group mt-2" >
                <div className="form-input" >
                <div>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <button style={{ 
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: 'black',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                boxShadow: '2px 4px 6px 2px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s',
              }}>X</button>
            </Link>
          </div>
                </div>
            </div>
        </form>
    <div className="auth-content">
        
        <br />
        <div>
            <h1 className='text-white'>{setting.about_title} </h1>
           
        </div>
    </div>
</div>
          

        <div className="about-section">
  <div className="about-item">
    <div className="about-title-head">About Us</div>
    <div className="about-description" dangerouslySetInnerHTML={{ __html: setting.about_desc }} />
  </div>
  
  <div className="about-item">
    <div className="about-title">{setting.mission_title}</div>
    <div className="about-description" dangerouslySetInnerHTML={{ __html: setting.mission_desc }} />
  </div>
  
  <div className="about-item">
    <div className="about-title">{setting.service_title}</div>
    <div className="about-description" dangerouslySetInnerHTML={{ __html: setting.service_desc }} />
  </div>
  
  <div className="about-item">
    <div className="about-title">{setting.vision_title}</div>
    <div className="about-description" dangerouslySetInnerHTML={{ __html: setting.vision_desc }} />
  </div>
</div>



            
        </div>
    );
}

export default About;
