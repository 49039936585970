import React from 'react';
import profile from './assets/profile.png';
import profile2 from './assets/profile2.png';
import { Link } from 'react-router-dom';
import './tree.css';

const TreeNode = ({ node }) => {
  return (
    <Link to={`/binary_tree?id=${node.user_id || '#'}`}>
    <div
      style={{
        borderRadius: '10px',
        padding: '10px',
        marginTop: '10px',
        marginLeft: '20px',
        textAlign: 'center',
        position: 'relative',
      }}
      className="tooltip-container"
    >
      {node.is_active === 'active' ? (
        <>
          <img className='profile-img' src={profile} alt='profile' /><br />
        </>
      ) : (
        <>
          <img className='profile-img' src={profile2} alt='alternate profile' /><br />
        </>
      )}
      <span>{node.label}</span>

      {/* Tooltip Content */}
      <div className="tooltip-content">
        <p><strong>Sponsor:</strong> {node.sponcer}</p>
        <p><strong>Name:</strong> {node.name}</p>
        <p><strong>Package:</strong> {node.package}</p>
        <p><strong>Status:</strong> {node.is_active}</p>
      </div>
    </div>
  </Link>
  );
};

export default TreeNode;
