import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import New_Footer from '../common/new_footer';
import "../new_dash.css";

function New_report() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('deposite');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  // Pagination
  const [reason, setReason] = useState('deposit');
  const [pageNumber, setPageNumber] = useState(0);
  const perPage = 10;

  useEffect(() => {
    if (pageNumber !== 0) {
      setPageNumber(0); // Reset the page number when a new tab is selected
    } else {
      fetchData(0); // Fetch data for the new tab with the first page
    }
  }, [selectedTab]);

  useEffect(() => {
    fetchData(pageNumber); // Fetch data for the current page
  }, [pageNumber]);

  const handleTabClick = (tab, reasonText) => {
    setSelectedTab(tab);
    setReason(reasonText);
  };

  const fetchData = async (pageNumber) => {
    setLoading(true);
    setError(null);
    try {
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/Transaction/${id}/${reason}?page=${pageNumber + 1}`);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const months = [
      'Jan', 'Feb', 'March', 'April', 'May', 'June',
      'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const reasonMapping = {
    "deposit": "Deposite",
    "activate_package": "Activate Package",
    "withdraw_payment": "Withdraw",
    "direct_income": "Direct Income",
    "daily_payout": "Daily Income",
    "monthly_income": "Monthly Income",
    "level_income": "Level",
    "monthly_bonus": "Monthly Bonus",
  };

  // Pagination logic
  const pageCount = data?.transaction?.last_page || 0;

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
  };

  const renderPagination = () => {
    const startPage = Math.max(0, pageNumber - 1);
    const endPage = Math.min(pageCount - 1, pageNumber + 1);

    return (
      <div className="pagination-container">
        <button 
          className="pagination-button" 
          disabled={pageNumber === 0} 
          onClick={() => handlePageChange(pageNumber - 1)}
        >
          &lt; Prev
        </button>

        {startPage > 0 && (
          <>
            <button 
              className="pagination-button" 
              onClick={() => handlePageChange(0)}
            >
              1
            </button>
            {startPage > 1 && <span className="pagination-ellipsis">...</span>}
          </>
        )}

        {[...Array(endPage - startPage + 1)].map((_, index) => (
          <button 
            key={startPage + index} 
            className={`pagination-button ${pageNumber === startPage + index ? 'active' : ''}`} 
            onClick={() => handlePageChange(startPage + index)}
          >
            {startPage + index + 1}
          </button>
        ))}

        {endPage < pageCount - 1 && (
          <>
            {endPage < pageCount - 1 && <span className="pagination-ellipsis">...</span>}
            <button 
              className="pagination-button" 
              onClick={() => handlePageChange(pageCount - 1)}
            >
              {pageCount}
            </button>
          </>
        )}

        <button 
          className="pagination-button" 
          disabled={pageNumber === pageCount - 1} 
          onClick={() => handlePageChange(pageNumber + 1)}
        >
          Next &gt;
        </button>
      </div>
    );
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Sidebar />
      <section className="body-content">
        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', margin: '0 0 10px 0', overflowX: 'auto' }}>
          <h6 className={`report_tab ${selectedTab === 'deposite' ? 'report_active' : ''}`} onClick={() => handleTabClick('deposite', 'deposit')}>Deposite</h6>
          <h6 className={`report_tab ${selectedTab === 'activation' ? 'report_active' : ''}`} onClick={() => handleTabClick('activation', 'activate_package')}>Activation</h6>
          <h6 className={`report_tab ${selectedTab === 'withdraw' ? 'report_active' : ''}`} onClick={() => handleTabClick('withdraw', 'withdraw_payment')}>Withdraw</h6>
          <h6 className={`report_tab ${selectedTab === 'daily' ? 'report_active' : ''}`} onClick={() => handleTabClick('daily', 'daily_payout')}>DailyPayout</h6>
          <h6 className={`report_tab ${selectedTab === 'direct' ? 'report_active' : ''}`} onClick={() => handleTabClick('direct', 'direct_income')}>DirectIncome</h6>
          <h6 className={`report_tab ${selectedTab === 'salary' ? 'report_active' : ''}`} onClick={() => handleTabClick('salary', 'monthly_income')}>MonthlyIncome</h6>
          <h6 className={`report_tab ${selectedTab === 'level' ? 'report_active' : ''}`} onClick={() => handleTabClick('level', 'level_income')}>LevelIncome</h6>
          <h6 className={`report_tab ${selectedTab === 'monthlybonus' ? 'report_active' : ''}`} onClick={() => handleTabClick('monthlybonus', 'monthly_bonus')}>MonthlyBonus</h6>
        </div>

        <div>
          {loading ? (
            <div style={{ textAlign: 'center' }}>Loading...</div>
          ) : error ? (
            <div style={{ textAlign: 'center' }}>Error: {error.message}</div>
          ) : data && data.transaction && data.transaction.data && data.transaction.data.length > 0 ? (
            <section>
              <div>
                {data.transaction.data.map((deposite, index) => (
                  <div className="col-12" key={index}>
                    <div className="report-card">
                      <div className="d-flex justify-content-between">
                        <div className="align-content-center report-content">
                          <div className="report-name">{reasonMapping[deposite.reason]}{" "}{deposite.reason === "level_income" && deposite.level}</div>
                          <div>{deposite.approval}</div>
                        </div>
                        <div className="align-content-center">
                          <span className="align-content-center report-date">{formatDate(deposite.date)}</span>
                        </div>
                        <div className="align-content-center copy-btn">
                          {Number.isInteger(Number(deposite.amount)) 
                            ? deposite.amount 
                            : Number(deposite.amount).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {data.transaction.data.length > 0 && renderPagination()}
              </div>
            </section>
          ) : (
            <div style={{ textAlign: 'center' }}>No transactions available</div>
          )}
        </div>
      </section>
      <New_Footer />
    </div>
  );
}

export default New_report;
