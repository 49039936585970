import React, { useState } from "react";
import MyModal from "./mymodal";

import "./modal.css";

const Modal = ({ message, closeModal }) => {
  const handleCloseButton = (
    <button className="model-btn" onClick={closeModal}>
      Accept It
    </button>
  );

  const mainModal = (
    <MyModal closeModal={closeModal} handleCloseButton={handleCloseButton}>
      <h2 className="modal-head-self">{message.title}</h2>
      <div className="modal-content-self" dangerouslySetInnerHTML={{ __html: message.content }} />
    </MyModal>
  );

  return <>{mainModal}</>;
};

export default Modal;