import React from "react";
import ReactDOM from "react-dom/client";
import { useParams } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import Footer from "./footer";
import Sidebar from "./Sidebar";
import Splash from "./Pages/Splash";
import Signin from "./Pages/Signin";
import Signup from "./Pages/Signup";
import About from "./About";
import Recommandation from "./recommandation";
import Market_trend from "./market_trends";
import Open_call from "./open_call";
import Stock from "./stock";
import Notification from "./notification";
import Blog from "./blog";
import Blog_details from "./blog_details";
import Register from "./register";
import Login from "./login";
import Forget_password from "./forget_password";
import OTP from "./otp";
import Reset_password from "./reset_password";
import Profile from "./profile";
import Account from "./account";
import FAQ from "./faq";
import Change_password from "./change_password";
import OTP1 from "./otp1";
import Reset_password1 from "./reset_password1";
import MyModal from "./mymodal";
import Modal from "./modal";
import Recommendation from "./recommandation";
import Recomm_details from "./recomm_details";
import Insights from "./insights";
import Risk_Management from "./risk_management";
import RiskManagement from "./risk_management";
import Stock_Risk from "./stock_risk";
import Options_Stock from "./options_stock";
import Future_Stock from "./future_stock";
import CheckOut from "./check_out";
import ProceedPayment from "./proceed_payment";
import Subcription from "./subcription";
import SubcriptionPlans from "./subcriptions_plans";
import UserProfile from "./user_profile";
import Pricing from "./pricing";
import Privacy from "./privacy";
import Terms from "./terms";
import Return from "./return";
import Team from "./team";
import Deposite from "./deposite";
import Deposite_report from "./deposite_report";
import Withdraw from "./withdraw";
import Withdraw_report from "./withdraw_report";
import Activation from "./activation";
import Direct from "./direct";
import Matching from "./matching";
import Salary from "./salary";
import Activation_report from "./acitvation_report";
import Report from "./report";
import Binary_Tree from "./binary_tree";
import Transfer from "./transfer";
import My_direct from "./my_direct";
import New_dash from "./new_dash";
import New_deposite from "./activation/new_deposite";
import New_report from "./reports/new_report";
import Level from "./level";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<New_dash />} />
        <Route path="/About" element={<About />} />
        <Route path="/activation" element={<Activation />} />
        <Route path="/deposite" element={<Deposite />} />
        <Route path="/deposite_report" element={<Deposite_report />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/account" element={<Account />} />
        {/* <Route path="/blog" element={<Blog />} /> */}
        {/* <Route path="/blog_details/:id" element={<Blog_details />} /> */}
        {/* <Route path="/market_trends" element={<Market_trend />} /> */}
        {/* <Route path="/open_call" element={<Open_call />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<Forget_password />} />
        <Route path="/change_password" element={<Change_password />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/otp1" element={<OTP1 />} />
        <Route path="/reset_password" element={<Reset_password />} />
        <Route path="/reset_password1" element={<Reset_password1 />} />
        {/* <Route path="/recommandation" element={<Recommendation />} /> */}
        {/* <Route path="/recomm_details/:id" element={<Recomm_details />} /> */}
        {/* <Route path="/stock" element={<Stock />} /> */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/Sidebar" element={<Sidebar />} />
        <Route path="/Splash" element={<Splash />} />
        <Route path="/Signin" element={<Signin />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/mymodal" element={<MyModal />} />
        <Route path="/modal" element={<Modal />} />
        <Route path="/insights" element={<Insights />} />
        {/* <Route path="/risk_management" element={<RiskManagement />} /> */}
        <Route path="/activation_report" element={<Activation_report />} />
        <Route path="/report" element={<Report />} />
        <Route path="/binary_tree" element={<Binary_Tree />} />
        <Route path="/my_direct" element={<My_direct />} />
        
        <Route path="/stock_risk" element={<Stock_Risk />} />
        <Route path="/pricing" element={<Pricing />} /> 
        {/* <Route path="/options_stock" element={<Options_Stock />} /> */}
        {/* <Route path="/future_stock" element={<Future_Stock />} /> */}
        <Route path="/transfer" element={<Transfer />} />

        <Route path="/direct" element={<Direct />} />
        <Route path="/matching" element={<Matching />} />
        <Route path="/salary" element={<Salary />} />
        <Route path="/return" element={<Return />} />

        {/* <Route path="/check_out" element={<CheckOut />} /> */}

        {/* <Route path="/proceed_payment" element={<ProceedPayment />} /> */}

        {/* <Route path="/subcription" element={<Subcription />} />
        <Route path="/privacy" element={<Privacy />} /> */}
        <Route path="/terms" element={<Terms />} />
        <Route path="/team" element={<Team />} />

        {/* <Route path="/subcriptions_plans" element={<SubcriptionPlans />} /> */}
        <Route path="/user_profile" element={<UserProfile />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/withdraw_report" element={<Withdraw_report />} />
        <Route path="/New_dash" element={<App />} />
        <Route path="/New_deposite" element={<New_deposite />} />
        <Route path="/New_report" element={<New_report />} />
        <Route path="/level" element={<Level />} />
        
      </Routes>
    </Router>
  </React.StrictMode>
);
