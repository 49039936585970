import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import scan from './assets/theme_asset/svg/scan.svg';
import user from './assets/theme_asset/svg/user.svg';
import user_fill from './assets/theme_asset/svg/user-fill.png';
import mpay from './assets/theme_asset/svg/mpay.svg';
import mpay_fill from './assets/theme_asset/svg/m-fill.png';
import bar from './assets/theme_asset/svg/bar-chart.svg';
import bar_fill from './assets/theme_asset/svg/bar-fill.png';

const Footer = () => {
  const location = useLocation();

  return (
    <div className='footer-change'>
      <section className="panel-space" />
      <div className="navbar-menu">
        <div className="scanner-bg">
          <Link to="/deposite" className="scanner-btn">
            <img className="img-fluid" src={scan} alt="scan" />
          </Link>
        </div>
        <ul>
          <li className={location.pathname === '/' ? 'active' : ''}>
            <Link to="/">
              <div className="icon">
                <img className="unactive" src={mpay} alt="mPay" />
                <img className="active" src={mpay_fill} alt="mPay" style={{width:'18px',marginBottom:'2px'}} />
              </div>
              <h5 className={location.pathname === '/' ? 'active' : ''}>Home</h5>
            </Link>
          </li>
          <li className={location.pathname === '/team' ? 'active' : ''}>
            <Link to="/team">
              <div className="icon">
                <img className="unactive" src={bar} alt="categories" />
                <img className="active" src={bar_fill} alt="categories" style={{width:'18px',marginBottom:'2px'}}/>
              </div>
              <h5 className={location.pathname === '/team' ? 'active' : ''}>Team</h5>
            </Link>
          </li>
          <li>
            {/* Leave this empty for spacing */}
          </li>
          <li className={location.pathname === '/blog' ? 'active' : ''}>
            <Link to="/blog">
              <div className="icon">
                <img className="unactive" src={bar} alt="bag" />
                <img className="active" src={bar_fill} alt="bag" style={{width:'18px',marginBottom:'2px'}}/>
              </div>
              <h5 className={location.pathname === '/blog' ? 'active' : ''}>Info Bits</h5>
            </Link>
          </li>
          <li className={location.pathname === '/profile' ? 'active' : ''}>
            <Link to="/profile">
              <div className="icon">
                <img className="unactive" src={user} alt="profile" />
                <img className="active" src={user_fill} alt="profile" style={{width:'18px',marginBottom:'2px'}}/>
              </div>
              <h5 className={location.pathname === '/profile' ? 'active' : ''}>Profile</h5>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
