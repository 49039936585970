
  import logo from './assets/theme_asset/CAPITAL.png';

  import feather from 'feather-icons';
  
  import axios from 'axios';
  import { useState } from 'react';
  import { useNavigate } from 'react-router-dom';
  import { Route, Routes } from 'react-router-dom';
  import Login from './login';
  
  import { Link } from 'react-router-dom';
  
  import { BiSolidLeftArrow } from 'react-icons/bi';
  
  
  import token1 from './assets/theme_asset/token/21.png';
  import token2 from './assets/theme_asset/token/22.png';
  import token3 from './assets/theme_asset/token/23.png';
  import token4 from './assets/theme_asset/token/24.png';
  import token5 from './assets/theme_asset/token/25.png';
  import token6 from './assets/theme_asset/token/26.png';
  import token7 from './assets/theme_asset/token/27.png';
  import token8 from './assets/theme_asset/token/28.png';
  import token9 from './assets/theme_asset/token/29.png';
  import token10 from './assets/theme_asset/token/30.png';
  import color1 from './assets/theme_asset/token/color1.png';
  import color2 from './assets/theme_asset/token/color2.png';
  import color3 from './assets/theme_asset/token/color3.png';
  import deposite1 from './assets/theme_asset/deposit.png'
  import reward from './assets/theme_asset/reward.png'
  
  import Footer from './footer';
  import Sidebar from './Sidebar';
  
  
  import './game.css';
  
  import { useEffect } from 'react';
  
  import Modal from "./modal";
  import SkeletonLoader from './SkeletonLoader';
  
  import 'slick-carousel/slick/slick.css';
  import 'slick-carousel/slick/slick-theme.css';
  import Pagination from './react-pegination';
  import New_Footer from './common/new_footer';
  
  
  
  
  
  
  function Team() {
  
    const navigate = useNavigate();
  
  
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
  
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  
    const [recMetrics,setRecMetrics] = useState([0,0,0])
    
    const reports = []; // Your report data
    const perPage = 10; // Number of reports per page
  
    // Pagination
    const [pageNumber, setPageNumber] = useState(0);
    const pageCount = Math.ceil(reports.length / perPage);
  
    const handlePageClick = ({ selected }) => {
      setPageNumber(selected);
    };
  
    // Pagination logic to slice the reports array
    const offset = pageNumber * perPage;
    const currentPageReports = reports.slice(offset, offset + perPage);
  
  
    useEffect(() => {
        fetchData();
    }, [pageNumber]);
  
  
  
  
    const fetchData = async () => {
      try {
        
        const id = localStorage.getItem('id');
        const response = await axios.get(`${apiBaseUrl}/userteamapi/${id}?page=${pageNumber + 1}`);
        setData(response.data);
        
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
  
  
    const [modalMessage3, setModalMessage] = useState(null);
    useEffect(() => {
      // Replace icons after component is mounted
      const storedMessage = localStorage.getItem('modalMessage3');
        if (storedMessage) {
          setModalMessage(JSON.parse(storedMessage));
          // Remove modal message from local storage after retrieving
          localStorage.removeItem('modalMessage3');
        }
  
         
      
      const token = localStorage.getItem('token');
      if(token == null){
        
          navigate('/login');
        
      }
      feather.replace();
  
      fetchData();
    }, []);
  
  
    
    if (loading) {
      
        <SkeletonLoader />
      
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    if(data == null)
      {
        return (
          <SkeletonLoader showHeader={false} showFooter={false} /> 
  
         )
      }
  
      const formatDate = (dateString) => {
        const months = [
          'Jan', 'Feb', 'March', 'April', 'May', 'June',
          'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ];
    
        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
    
        return `${day} ${month} ${year}`;
      };
    
   
      return (
  
  
        <body className=''>
        <div >
            
  
      <Sidebar/>       
        
      <section>
    <div >
     <div className="body-content">
      <center><h1>Team Section</h1></center>
  {data && data.my_downline.length > 0 ? (
    <section>
      
        {data.my_downline.map((user, index) => (
          <div key={index} className="col-12">
             <div className="transaction-box">
              <a href="#" >
                
                <div className="d-flex justify-content-between">
                  <div className="align-content-center">
                    <div >
                   
                    <div style={{
                        color: 'black',
                        width: '140px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                        {user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)}
                      </div>

                  <div className="transaction-name-2">
                    {user.user_id} 
                    
                  </div>
                  </div>
                  <div style={{color:'black'}}>
                    
                  </div>
                  </div>
                  <div className="align-content-center">
                    <span className="align-content-center report-date">{formatDate(user.joining_date)}</span><br/>
                    <h6 className='transaction-btn mt-2'>{user.is_active === 'active' ? 'Active' : 'Inactive'}(Level{" "}{user.level})</h6>
                  </div>
                  
                  <div className="align-content-center text-center">
                  <span style={{color:'black',fontSize:'14px'}}>Sponcer</span><br />
                  <span style={{color:'black',fontSize:'14px'}}>{user.sponcer_id}</span><br />
                  
                  </div>
                </div>
              </a>
            </div>
          </div>
        ))}
      
    </section>
  ) : (
    <div style={{textAlign:'center'}}>No Team data available</div>
  )}
           
           
           </div>
         </div>
         </section>
         <New_Footer />
         </div>
         </body>
    );
    }
  
    export default Team;
  